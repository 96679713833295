.inperson-form-container {
  width: 60%;
  margin: auto;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .request-form {
    border: 1px solid #d0d5dd;
    border-radius: 8px !important;
    padding: 16px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two equal columns */
    gap: 16px;
    margin: auto;
    margin-top: 8px;

    /* Full-width field */
    .full-width {
      grid-column: span 2;
    }

    .submit-btn {
      display: flex;
      justify-content: center;
    }

    .form-label {
      color: #3e445b;
      font-size: 14px;
      font-weight: 500;
      display: block;
      margin-bottom: 4px;
      .required-symbol {
        color: #f43c3c;
      }
    }
  }
}
