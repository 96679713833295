.tag {
  padding: 3px 13px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.normal {
    background-color: rgba(26, 38, 49, 0.1);
  }

  &.blue {
    background-color: #d7e8fc;
  }

  &.pill {
    background-color: #428bb5;
    border-radius: 100px;
    height: 20px;
  }
}
